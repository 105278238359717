<template>
    <div class="ma-0 pt-0">
        <v-card flat>
            <v-row align="center" justify="end">
                <v-col cols="12" sm="6" md="4">
                    <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable
                        placeholder="Search">
                        <template slot="prepend">
                            <el-button @click="addFBO"><i class="el-icon-plus"></i></el-button>
                        </template>
                    </el-input>
                </v-col>
            </v-row>
            <v-card-text>
                <v-data-table dense fixed-header hide-default-footer :loading="loading" :items="fbo.data"
                    :headers="headers" height="65vh" style="cursor: pointer" :options="{
                        page: page,
                        itemsPerPage: params.limit,
                        pageStart: 1,
                        pageStop: Math.round(fbo.total / params.limit),
                        pageCount: Math.round(fbo.total / params.limit),
                        itemsLength: fbo.total
                    }">
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn-toggle borderless>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @click="edit(item)" small>
                                        <v-icon small>edit</v-icon>
                                    </v-btn>
                                </template>
                                <span style="font-size: 12px">Edit FBO</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @click="remove(item)" small color="red">
                                        <v-icon small color="red">delete</v-icon>
                                    </v-btn>
                                </template>
                                <span style="font-size: 12px">Delete FBO</span>
                            </v-tooltip>

                        </v-btn-toggle>
                    </template>
                    <template v-slot:footer class="text-center">
                        <div class="text-center pt-2" style="background: var(--v-component-base) !important">
                            <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page"
                                :page-size.sync="params.limit" :pager-count="5" :page-sizes="[18, 25, 50, 100]" :layout="
                                    !$vuetify.breakpoint.mobile
                                        ? 'sizes, prev, pager, next, jumper, total'
                                        : 'prev, pager, next'
                                " :total="fbo.total">
                            </el-pagination>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="modal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card flat>
                <v-toolbar flat color="transparent">
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text icon @click="saveFBO" :loading="savingFBO"><v-icon>save</v-icon></v-btn>
                    <v-btn text @click="modal = false, item = {}">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="6" class="my-0 py-0">
                            <v-text-field label="FBO Code" v-model="item.fboCode" outlined></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="my-0 py-0">
                            <v-text-field label="Company" v-model="item.fboName" outlined></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="my-0 py-0">
                            <v-text-field label="Type" v-model="item.fboType" outlined></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="my-0 py-0">
                            <v-text-field label="Production Area" v-model="item.productionArea" outlined></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="my-0 py-0">
                            <v-text-field label="Area Code" v-model="item.productionCode" outlined></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        fbo: {},
        headers: [
            {
                text: "Action",
                value: "action",
                sortable: false,
                align: "center"
            },
            {
                text: "Code",
                value: "fboCode",
                sortable: false,
                align: "center",
            },
            {
                text: "Company",
                value: "fboName",
                align: "center",
            },
            {
                text: "Type",
                value: "fboType",
                align: "center",
            },
            {
                text: "Production Area",
                value: "productionArea",
                align: "center",
            },
        ],
        item: {},
        modal: false,
        page: 1,
        params: {
            offset: 0,
            limit: 15,
            search: null
        },
        savingFBO: false
    }),
    watch: {
        async page(value) {
            this.params.offset = (value - 1) * this.params.limit;
            await this.listFbo();
        },
        "params.limit": {
            immediate: true,
            async handler() {
                this.page = 1;
                await this.listFbo();
            },
        },
        "params.search": {
            async handler() {
                await this.listFbo();
            },
        }
    },
    created() {
        this.listFbo()
    },
    methods: {
        async addFBO() {
            this.item = {}
            this.modal = true
        },
        edit(item) {
            this.item = item
            this.modal = true
        },
        async listFbo() {
            this.loading = true
            this.fbo = await this.$API.getFbo({ params: this.params })
            this.loading = false
        },
        async remove(item) {
            this.$confirm(
                "Are you sure you want to delete this FBO?",
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            )
                .then(async () => {
                    this.loading = true
                    await this.$API.updateFBO({
                        id: item.id,
                        isActive: false,
                        isDeleted: true,
                    });
                    this.listFbo();
                    this.$message({
                        type: "success",
                        message: "Successfully deleted!",
                    });
                    this.loading = false
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Delete canceled",
                    });
                });
        },
        async saveFBO(){
            this.savingFBO=true
            if(this.item.id){
                await this.$API.updateFBO(this.item)
                this.$message({
                        type: "success",
                        message: "Successfully updated!",
                    });
            }else{
                await this.$API.createFBO(this.item)
                this.$message({
                        type: "success",
                        message: "Successfully created!",
                    });
            }
            this.listFbo()
            this.modal=false
            this.item={}
            this.savingFBO=false
            
        }
    }
}
</script>