<template>
  <div>
    <div v-if="!loading">
      <v-toolbar flat color="tranpsarent" dense fixed>
        <v-btn icon @click="$router.go(-1)"><v-icon>arrow_back</v-icon></v-btn>
        <v-avatar size="48" class="mr-1">
          <v-img
            contain
            v-if="country.code"
            :src="`https://cdn.loglive.io/flags/4x3/${country.code.toLowerCase()}.svg`"
          ></v-img>
        </v-avatar>
        <v-toolbar-title
          >{{ country.name }} ({{ country.iso2 }}) ({{
            country.iso3
          }})</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-tabs dense show-arrows v-model="selectedTab">
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :value="tab"
        >
          {{ tab }}</v-tab
        >
      </v-tabs>
      <v-card
        flat
        color="transparent"
        style="max-height: 80vh; overflow-y: auto"
      >
        <v-card-text>
          <div v-if="selectedTab == 0">
            <v-row>
              <v-col col="12" sm="12" md="4">
                <gmap-map
                  :zoom="zoom"
                  :center="center"
                  :options="{
                    disableDefaultUI: true,
                  }"
                  ref="mapRef"
                  style="width: 100%; height: 30vh"
                >
                  <gmap-info-window
                    v-if="marker"
                    :key="markerKey"
                    :options="{
                      maxWidth: 300,
                    }"
                    :opened="showMarker"
                    :position="marker.marker"
                  >
                    <v-card
                      style="background: white"
                      class="grey--text pa-0 ma-0"
                    >
                      <v-card-text class="grey--text pa-0 ma-0">
                        <b style="font-size: 16px">{{ marker.name }}</b>
                        <br />
                        <span style="font-size: 14px">{{ marker.code }}</span>
                      </v-card-text>
                    </v-card>
                  </gmap-info-window>
                  <div v-if="displayPorts">
                    <gmap-marker
                      v-for="(port, indexLocation) in portMarkers"
                      :key="port.id"
                      :position="port.marker"
                      :clickable="true"
                      @mouseover="showByIndex = indexLocation"
                      @mouseout="showByIndex = null"
                    >
                      <gmap-info-window :opened="showByIndex === indexLocation">
                        <v-card
                          style="background: white"
                          class="grey--text pa-0 ma-0"
                        >
                          <v-card-text class="grey--text pa-0 ma-0">
                            <b style="font-size: 16px">{{ port.name }}</b>
                            <br />
                            <span style="font-size: 14px">{{ port.code }}</span>
                          </v-card-text>
                        </v-card>
                      </gmap-info-window>
                    </gmap-marker>
                  </div>
                </gmap-map>
                <v-row>
                  <v-col cols="12" sm="12" md="6" class="mx-0 px-0 mb-0 pb-0">
                    <v-list dense class="my-0 py-0">
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>account_balance</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ country.capital }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Capital City
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>schedule</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            <!-- {{ country.timeZoneInCapital }} -->
                            {{ country.timeZone }}
                          </v-list-item-title>
                          <!-- <v-list-item-subtitle>{{
                        country.timeZone
                      }}</v-list-item-subtitle> -->
                          <v-list-item-subtitle>
                            Timezone
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>attach_money</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            ${{ formatNumber(country.gdp) }}
                          </v-list-item-title>
                          <v-list-item-subtitle> GDP </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>phone</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            +{{ country.e164 }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Dialling Code
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="mx-0 px-0">
                    <v-list dense class="my-0 py-0">
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>public</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ country.continent }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Continent
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>paid</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ country.currencyName }} ({{
                              country.currencyCode
                            }})
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Currency
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>groups</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ country.population }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Population
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>map</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ formatNumber(country.countrySize) }} Km<sup
                              >2</sup
                            >
                          </v-list-item-title>
                          <v-list-item-subtitle> Area </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-list subheader>
                    <v-subheader>Languages</v-subheader>
                    <v-list-item>
                      <v-list-item-content class="text-wrap">
                        <span style="font-size: 14px">
                          {{ country.languages }}</span
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-list dense subheader>
                  <v-subheader style="font-size: 16px"
                    >
                    Telemetry
                  </v-subheader>
                  <div class="my-1 py-2">
                    <v-row
                    justify="space-between"
                    class="mx-3"
                  >
                  <v-checkbox label="2G" v-model="country['2g']" @change="updateCountry"></v-checkbox>
                  <v-checkbox label="3G" v-model="country['3g']" @change="updateCountry"></v-checkbox>
                  <v-checkbox label="4G" v-model="country['4g']" @change="updateCountry"></v-checkbox>
                  <v-checkbox label="5G" v-model="country['5g']" @change="updateCountry"></v-checkbox>

               
                </v-row>
                  </div>
                  <v-subheader style="font-size: 16px"
                    ><v-chip
                      v-if="ports && ports.length > 0"
                      small
                      class="mr-1"
                      >{{ ports.length }}</v-chip
                    >
                    City & Towns <v-spacer></v-spacer>
                    <v-switch v-model="displayPorts" color="primary"></v-switch>
                    <v-btn icon color="primary" @click="addPort()"
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    >
                  </v-subheader>
                  <v-text-field
                    label="Search"
                    outlined
                    dense
                    clearable
                    prepend-inner-icon="search"
                    v-model="searchPorts"
                    class="px-2 mb-0 pb-0 mt-2"
                  ></v-text-field>
                  <v-list
                    dense
                    subheader
                    style="max-height: 60vh; overflow-y: auto"
                    v-if="!loadingPorts"
                    class="mt-0 pt-0"
                  >
                    <v-list-item v-for="port in filterPorts" :key="port.id">
                      <v-list-item-action>
                        <v-tooltip left v-if="port.type == 'Sea'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                              >directions_boat_filled</v-icon
                            >
                          </template>
                          <span style="font-size: 12px">Seaport</span>
                        </v-tooltip>

                        <v-tooltip
                          left
                          v-else-if="
                            port.type == 'River' || port.type == 'Lake'
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">sailing</v-icon>
                          </template>
                          <span style="font-size: 12px"
                            >{{ port.type }}port</span
                          >
                        </v-tooltip>

                        <v-tooltip left v-else-if="port.type == 'Air'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">flight</v-icon>
                          </template>
                          <span style="font-size: 12px">Airport</span>
                        </v-tooltip>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ port.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ port.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn icon small @click="editPort(port)"
                            ><v-icon small>edit</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            small
                            color="red"
                            @click="deletePort(port)"
                            ><v-icon small>delete</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            small
                            @click="viewPort(port)"
                            v-if="port.latitude && port.longitude"
                            ><v-icon small>location_searching</v-icon></v-btn
                          >
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-list
                    v-else
                    style="height: 60vh; overflow-y: auto"
                    v-loading="loadingPorts"
                  ></v-list>
                </v-list>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-list dense subheader>
                  <v-subheader style="font-size: 16px"
                    >Subdivisions <v-spacer></v-spacer>
                    <v-btn icon color="primary" @click="addSubdivision()"
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    >
                  </v-subheader>
                  <v-text-field
                    label="Search"
                    outlined
                    dense
                    clearable
                    prepend-inner-icon="search"
                    v-model="seearchSubDivisions"
                    class="px-2 mb-0 pb-0 mt-2"
                  ></v-text-field>
                  <v-list
                    class="mt-0 pt-0"
                    dense
                    subheader
                    style="max-height: 30vh; overflow-y: auto"
                  >
                    <v-list-item
                      v-for="subdivision in filterDivisions"
                      :key="subdivision.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ subdivision.name }} ({{ subdivision.code }})
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 12px">
                          {{ subdivision.type }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn
                            icon
                            small
                            @click="editSubdivision(subdivision)"
                            ><v-icon small>edit</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            small
                            color="red"
                            @click="deleteSubdivision(subdivision)"
                            ><v-icon small>delete</v-icon></v-btn
                          >
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="subdivisions.length == 0">
                      <v-list-item-content
                        class="text-center grey--text"
                        style="font-size: 12px"
                      >
                        No subdivisions
                      </v-list-item-content></v-list-item
                    >
                  </v-list>
                  <v-list subheader>
                    <v-subheader style="font-size: 16px"
                      >Regulatory Bodies <v-spacer></v-spacer
                      ><v-btn icon color="primary" @click="addRegulatoryBody()"
                        ><v-icon>add_circle_outline</v-icon></v-btn
                      ></v-subheader
                    >

                    <v-list
                      dense
                      subheader
                      style="max-height: 30vh; overflow-y: auto"
                    >
                      <v-list-item
                        v-for="body in regulatoryBodies"
                        :key="body.id"
                      >
                        <v-list-item-avatar>
                          <v-img v-if="body.logo" :src="body.logo"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ body.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ body.website }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row>
                            <v-btn icon @click="editRegulatoryBody(body)"
                              ><v-icon small>edit</v-icon></v-btn
                            >
                            <v-btn
                              icon
                              color="red"
                              @click="deleteRegulatoryBody(body)"
                              ><v-icon small>delete</v-icon></v-btn
                            >
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="regulatoryBodies.length == 0">
                        <v-list-item-content
                          class="text-center grey--text"
                          style="font-size: 12px"
                        >
                          No regulatory bodies
                        </v-list-item-content></v-list-item
                      >
                    </v-list>
                  </v-list>
                </v-list>
              </v-col>
            </v-row>
          </div>
          <div v-else-if="tabs[selectedTab] == 'Country Integrations'">
            <CountryIntegration
              :country="country"
            />
          </div>

          <div  v-else-if="tabs[selectedTab] == 'Export Documents'">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card flat tile color="transparent">
                  <v-card-title>
                    Export Documents
                    <v-btn icon color="primary" @click="addDocument('Export')"
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-text-field
                      style="max-width: 600px"
                      outlined
                      dense
                      v-model="searchExportDocuments"
                      label="Search"
                      prepend-inner-icon="search"
                      clearable
                    ></v-text-field>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="documentHeaders"
                      :items="exportDocuments"
                      :loading="loadingDocuments"
                      :search="searchExportDocuments"
                      style="cursor: pointer"
                      :expanded.sync="expanded"
                    >
                      <template v-slot:[`item.action`]="{ item }">
                        <v-row justify="center">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="editDocument(item)"
                                small
                                ><v-icon small>edit</v-icon></v-btn
                              >
                            </template>
                            <span style="font-size: 12px">Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteDocument(item)"
                                color="red"
                                small
                                ><v-icon small>delete</v-icon></v-btn
                              >
                            </template>
                            <span style="font-size: 12px">Delete</span>
                          </v-tooltip>
                          <v-tooltip
                            top
                            v-if="item.locationDocumentSubtypes.length > 0"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                color="primaryText"
                                small
                                @click="expandRow(item)"
                                ><v-icon>list</v-icon></v-btn
                              >
                            </template>
                            <span style="font-size: 12px"
                              >{{
                                item.locationDocumentSubtypes.length
                              }}
                              Variations</span
                            >
                          </v-tooltip>
                        </v-row>
                      </template>
                      <template v-slot:[`item.rank`]="{ item }">
                        <v-hover v-slot="{ hover }">
                          <v-row justify="center" align="center">
                            <v-btn
                              v-show="hover"
                              :disabled="item.rank == 1"
                              icon
                              color="success"
                              @click="changeRank('increase', item)"
                              ><v-icon>expand_less</v-icon></v-btn
                            >
                            {{ item.rank }}
                            <v-btn
                              v-show="hover"
                              icon
                              color="danger"
                              @click="changeRank('decrease', item)"
                              ><v-icon>expand_more</v-icon></v-btn
                            >
                          </v-row>
                        </v-hover>
                      </template>
                      <template v-slot:[`item.required`]="{ item }">
                        <v-icon v-if="item.required" color="blue"
                          >verified</v-icon
                        >
                      </template>

                      <template
                        v-slot:[`item.locationDocumentSubdivisions.length`]="{
                          item,
                        }"
                      >
                        <v-chip
                          small
                          class="white--text"
                          :color="
                            item.locationDocumentSubdivisions.length >=
                            subdivisions.length
                              ? 'success'
                              : item.locationDocumentSubdivisions.length == 0
                              ? 'danger'
                              : 'warning'
                          "
                          >{{
                            item.locationDocumentSubdivisions.length
                          }}</v-chip
                        >
                      </template>

                      <template
                        v-slot:[`item.locationSupportingDocuments.length`]="{
                          item,
                        }"
                      >
                        <v-chip
                          small
                          class="white--text"
                          color="primary"
                          v-if="item.locationSupportingDocuments.length > 0"
                          >{{ item.locationSupportingDocuments.length }}</v-chip
                        >
                      </template>
                      <template v-slot:[`item.productGroups`]="{ item }">
                        <v-chip
                          small
                          class="white--text"
                          v-if="
                            item.locationDocumentProducts.filter(
                              (x) => x.productGroupId
                            ).length > 0
                          "
                          >{{
                            item.locationDocumentProducts.filter(
                              (x) => x.productGroupId
                            ).length
                          }}</v-chip
                        >
                      </template>
                      <template v-slot:[`item.products`]="{ item }">
                        <v-chip
                          small
                          class="white--text"
                          v-if="
                            item.locationDocumentProducts.filter(
                              (x) => x.productId
                            ).length > 0
                          "
                          >{{
                            item.locationDocumentProducts.filter(
                              (x) => x.productId
                            ).length
                          }}</v-chip
                        >
                      </template>

                      <template v-slot:[`item.regions`]="{ item }">
                        <v-chip
                          small
                          class="white--text"
                          v-if="
                            item.locationDocumentAreas.filter((x) => x.regionId)
                              .length > 0
                          "
                          >{{
                            item.locationDocumentAreas.filter((x) => x.regionId)
                              .length
                          }}</v-chip
                        >
                      </template>
                      <template v-slot:[`item.countries`]="{ item }">
                        <v-chip
                          small
                          class="white--text"
                          v-if="
                            item.locationDocumentAreas.filter(
                              (x) => x.countryRegionId
                            ).length > 0
                          "
                          >{{
                            item.locationDocumentAreas.filter(
                              (x) => x.countryRegionId
                            ).length
                          }}</v-chip
                        >
                      </template>
                      <template v-slot:[`item.responsiblePartyType`]="{ item }">
                        <span style="text-transform: capitalize">{{
                          item.responsiblePartyType
                        }}</span>
                      </template>
                      <template v-slot:[`item.shared`]="{ item }">
                        <v-chip
                          small
                          v-if="item.shipper"
                          outlined
                          color="success"
                          class="mr-1 mt-1"
                        >
                          <v-icon small left>check</v-icon> Shipper</v-chip
                        >

                        <v-chip
                          small
                          v-if="item.forwarder"
                          outlined
                          color="success"
                          class="mr-1 mt-1"
                        >
                          <v-icon small left>check</v-icon> Forwarder</v-chip
                        >

                        <v-chip
                          small
                          v-if="item.consignee"
                          outlined
                          color="success"
                          class="mr-1 mt-1"
                        >
                          <v-icon small left>check</v-icon> Consignee</v-chip
                        >

                        <v-chip
                          small
                          v-if="item.buyer"
                          outlined
                          color="success"
                          class="mr-1 mt-1"
                        >
                          <v-icon small left>check</v-icon> Buyer</v-chip
                        >
                      </template>

                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-row class="mt-3 mb-2">
                            <v-col
                              cols="1"
                              v-if="$vuetify.breakpoint.mdAndUp"
                            ></v-col>
                            <v-col cols="12" class="my-0 py-0">
                              <v-card
                                outlined
                                v-for="variation in item.locationDocumentSubtypes"
                                :key="variation.id"
                                align="center"
                                class="pa-0 ma-0"
                              >
                                <v-card-text class="pa-0 ma-0">
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      md="2"
                                      class="mt-2 text-center"
                                    >
                                      <v-list-item style="height: 20px">
                                        <v-list-item-action>
                                          <v-chip>{{ variation.rank }}</v-chip>
                                        </v-list-item-action>
                                        <v-list-item-content class="text-left">
                                          <v-list-item-title>
                                            {{ variation.name }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-row>
                                            <v-btn
                                              class="mr-2"
                                              icon
                                              @click="
                                                editVariationDocument(variation)
                                              "
                                              ><v-icon small
                                                >edit</v-icon
                                              ></v-btn
                                            >
                                          </v-row>
                                        </v-list-item-action>
                                      </v-list-item>
                                      <v-list-item
                                        style="height: 20px"
                                        v-if="
                                          variation.file &&
                                          typeof variation.file == 'string'
                                        "
                                      >
                                        <v-list-item-action>
                                          <v-img
                                            style="width: 30px; height: 30px"
                                            :src="getIcon(variation.fileName)"
                                          >
                                          </v-img>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title class="text-left">
                                            {{ variation.fileName }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-row justify="center">
                                            <v-btn
                                              icon
                                              @click="
                                                downloadDocument(variation)
                                              "
                                              ><v-icon>download</v-icon>
                                            </v-btn>
                                          </v-row>
                                        </v-list-item-action>
                                      </v-list-item>
                                      <!-- <v-row align="center" justify="start">
                                      
                                      

                                        <h3>{{ variation.name }}</h3>
                                      </v-row> -->
                                      <v-col class="text-left mt-0 pt-0">
                                        <span
                                          style="
                                            font-weight: light;
                                            font-size: 12px;
                                          "
                                          >{{ variation.description }}</span
                                        >
                                      </v-col>
                                    </v-col>
                                    <v-col cols="12" sm="2" class="mr-0 pr-0">
                                      <v-list subheader dense>
                                        <v-subheader
                                        >
                                          Regions
                                          <v-spacer></v-spacer>
                                          <v-chip
                                            color="success"
                                            outlined
                                            small
                                            v-if="
                                              variation.regionMode == 'Include'
                                            "
                                            >Include</v-chip
                                          >
                                          <v-chip
                                            outlined
                                            color="danger"
                                            small
                                            v-else
                                            >Exclude</v-chip
                                          >
                                        </v-subheader>

                                        <v-row
                                          class="mb-1"
                                          v-if="!variation.allRegion"
                                        >
                                          <v-chip
                                            small
                                            class="mr-1 mt-1"
                                            outlined
                                            v-for="regionData in regionFilter(
                                              variation
                                            )"
                                            :key="regionData.id"
                                          >
                                            {{ regionData.name }}
                                          </v-chip>
                                        </v-row>

                                        <v-list-item v-if="variation.allRegion">
                                          <v-list-item-action>
                                            <v-icon
                                              v-if="
                                                variation.regionMode ==
                                                'Include'
                                              "
                                              color="success"
                                              >check_circle_outline</v-icon
                                            >
                                            <v-icon v-else color="danger"
                                              >cancel</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              class="text-left"
                                            >
                                              All Regions
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>

                                       
                                      </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="2" class="mr-0 pr-0">
                                      <v-list subheader dense>
                                        <v-subheader
                                        >
                                          Countries
                                          <v-spacer></v-spacer>
                                          <v-chip
                                            color="success"
                                            outlined
                                            small
                                            v-if="
                                              variation.countryMode == 'Include'
                                            "
                                            >Include</v-chip
                                          >
                                          <v-chip
                                            outlined
                                            color="danger"
                                            small
                                            v-else
                                            >Exclude</v-chip
                                          >
                                        </v-subheader>
                                        <v-list-item
                                          v-if="variation.allCountry"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              v-if="
                                                variation.countryMode ==
                                                'Include'
                                              "
                                              color="success"
                                              >check_circle_outline</v-icon
                                            >
                                            <v-icon v-else color="danger"
                                              >cancel</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              class="text-left"
                                            >
                                              All Countries
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <div v-else class="ml-2 my-4 pt-3">
                                          <v-row>
                                            <v-chip
                                              small
                                              class="mr-1 mt-1"
                                              outlined
                                              v-for="countryData in countryFilter(
                                                variation
                                              )"
                                              :key="countryData.id"
                                            >
                                              <v-avatar
                                                left
                                                size="24"
                                                class="mr-1"
                                                v-if="countryData.iso2"
                                              >
                                                <v-img
                                                  contain
                                                  :src="`https://cdn.loglive.io/flags/4x3/${countryData.iso2.toLowerCase()}.svg`"
                                                ></v-img>
                                              </v-avatar>
                                              {{ countryData.name }}
                                            </v-chip>
                                          </v-row>
                                        </div>
                                      </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="2" class="mr-0 pr-0">
                                      <v-list subheader dense>
                                        <v-subheader
                                        >
                                          Product Groups
                                          <v-spacer></v-spacer>
                                          <v-chip
                                            color="success"
                                            outlined
                                            small
                                            v-if="
                                              variation.productGroupMode ==
                                              'Include'
                                            "
                                            >Include</v-chip
                                          >
                                          <v-chip
                                            outlined
                                            color="danger"
                                            small
                                            v-else
                                            >Exclude</v-chip
                                          >
                                        </v-subheader>
                                        <v-list-item
                                          v-if="variation.allProductGroup"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              v-if="
                                                variation.productGroupMode ==
                                                'Include'
                                              "
                                              color="success"
                                              >check_circle_outline</v-icon
                                            >
                                            <v-icon v-else color="danger"
                                              >cancel</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              class="text-left"
                                            >
                                              All Product Groups
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <div
                                          v-else
                                          class="ml-2 my-4 pt-3"
                                        >
                                          <v-row>
                                            <v-chip
                                              small
                                              class="mr-1 mt-1"
                                              outlined
                                              v-for="productGroupData in productGroupFilter(
                                                variation
                                              )"
                                              :key="productGroupData.id"
                                            >
                                              {{ productGroupData.name }}
                                            </v-chip>
                                          </v-row>
                                        </div>
                                        </v-list>
                                        </v-col>

                                    <v-col cols="12" sm="2" class="mr-0 pr-0">
                                      <v-list subheader dense>
                                       

                                        <v-subheader
                                        >
                                          Products
                                          <v-spacer></v-spacer>
                                          <v-chip
                                            color="success"
                                            outlined
                                            small
                                            v-if="
                                              variation.productMode == 'Include'
                                            "
                                            >Include</v-chip
                                          >
                                          <v-chip
                                            outlined
                                            color="danger"
                                            small
                                            v-else
                                            >Exclude</v-chip
                                          >
                                        </v-subheader>
                                        <v-list-item
                                          v-if="variation.allProduct"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              v-if="
                                                variation.productMode ==
                                                'Include'
                                              "
                                              color="success"
                                              >check_circle_outline</v-icon
                                            >
                                            <v-icon v-else color="danger"
                                              >cancel</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              class="text-left"
                                            >
                                              All Products
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <div
                                          v-else
                                          class="ml-2 my-4 pt-3"
                                        >
                                          <v-row>
                                            <v-chip
                                              small
                                              class="mr-1 mt-1"
                                              outlined
                                              v-for="productData in productFilter(
                                                variation
                                              )"
                                              :key="productData.id"
                                            >
                                              {{ productData.name }}
                                            </v-chip>
                                          </v-row>
                                        </div>
                                      </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                      <v-list subheader dense>
                                       

                                        <v-subheader
                                        >
                                          Varieties
                                          <v-spacer></v-spacer>
                                          <v-chip
                                            color="success"
                                            outlined
                                            small
                                            v-if="
                                              variation.varietyMode == 'Include'
                                            "
                                            >Include</v-chip
                                          >
                                          <v-chip
                                            outlined
                                            color="danger"
                                            small
                                            v-else
                                            >Exclude</v-chip
                                          >
                                        </v-subheader>
                                        <v-list-item
                                          v-if="variation.allVariety"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              v-if="
                                                variation.varietyMode ==
                                                'Include'
                                              "
                                              color="success"
                                              >check_circle_outline</v-icon
                                            >
                                            <v-icon v-else color="danger"
                                              >cancel</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              class="text-left"
                                            >
                                              All Products
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <div
                                          v-else
                                          class="ml-2 my-4 pt-3"
                                        >
                                          <v-row>
                                            <v-chip
                                              small
                                              class="mr-1 mt-1"
                                              outlined
                                              v-for="productData in varietyFilter(
                                                variation
                                              )"
                                              :key="productData.id"
                                            >
                                              {{ productData.name }}
                                            </v-chip>
                                          </v-row>
                                        </div>
                                      </v-list>
                                    </v-col>
                                  </v-row>
                                </v-card-text></v-card
                              >
                            </v-col>
                           
                          </v-row>
                        </td>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-else-if="tabs[selectedTab] == 'Import Documents'">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card flat tile color="transparent">
                  <v-card-title>
                    Import Documents
                    <v-btn icon color="primary" @click="addDocument('Import')"
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-text-field
                      style="max-width: 600px"
                      outlined
                      dense
                      v-model="searchImportDocuments"
                      label="Search"
                      prepend-inner-icon="search"
                      clearable
                    ></v-text-field>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="documentHeaders"
                      :items="importDocuments"
                      :loading="loadingDocuments"
                      :search="searchImportDocuments"
                      style="cursor: pointer"
                      :expanded.sync="importExpanded"
                    >
                      <template v-slot:[`item.action`]="{ item }">
                        <v-row justify="center">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="editDocument(item)"
                                small
                                ><v-icon small>edit</v-icon></v-btn
                              >
                            </template>
                            <span style="font-size: 12px">Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteDocument(item)"
                                color="red"
                                small
                                ><v-icon small>delete</v-icon></v-btn
                              >
                            </template>
                            <span style="font-size: 12px">Delete</span>
                          </v-tooltip>
                          <v-tooltip
                            top
                            v-if="item.locationDocumentSubtypes.length > 0"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                color="primaryText"
                                small
                                @click="expandRow(item)"
                                ><v-icon>list</v-icon></v-btn
                              >
                            </template>
                            <span style="font-size: 12px"
                              >{{
                                item.locationDocumentSubtypes.length
                              }}
                              Variations</span
                            >
                          </v-tooltip>
                        </v-row>
                      </template>
                      <template v-slot:[`item.rank`]="{ item }">
                        <v-hover v-slot="{ hover }">
                          <v-row justify="center" align="center">
                            <v-btn
                              v-show="hover"
                              :disabled="item.rank == 1"
                              icon
                              color="success"
                              @click="changeRank('increase', item)"
                              ><v-icon>expand_less</v-icon></v-btn
                            >
                            {{ item.rank }}
                            <v-btn
                              v-show="hover"
                              icon
                              color="danger"
                              @click="changeRank('decrease', item)"
                              ><v-icon>expand_more</v-icon></v-btn
                            >
                          </v-row>
                        </v-hover>
                      </template>
                      <template v-slot:[`item.required`]="{ item }">
                        <v-icon v-if="item.required" color="blue"
                          >verified</v-icon
                        >
                      </template>
                      <template v-slot:[`item.responsiblePartyType`]="{ item }">
                        <span style="text-transform: capitalize">{{
                          item.responsiblePartyType
                        }}</span>
                      </template>
                      <template v-slot:[`item.shared`]="{ item }">
                        <v-chip
                          small
                          v-if="item.shipper"
                          outlined
                          color="success"
                          class="mr-1 mt-1"
                        >
                          <v-icon small left>check</v-icon> Shipper</v-chip
                        >

                        <v-chip
                          small
                          v-if="item.forwarder"
                          outlined
                          color="success"
                          class="mr-1 mt-1"
                        >
                          <v-icon small left>check</v-icon> Forwarder</v-chip
                        >

                        <v-chip
                          small
                          v-if="item.consignee"
                          outlined
                          color="success"
                          class="mr-1 mt-1"
                        >
                          <v-icon small left>check</v-icon> Consignee</v-chip
                        >

                        <v-chip
                          small
                          v-if="item.buyer"
                          outlined
                          color="success"
                          class="mr-1 mt-1"
                        >
                          <v-icon small left>check</v-icon> Buyer</v-chip
                        >
                      </template>

                      <template
                        v-slot:[`item.locationDocumentSubdivisions.length`]="{
                          item,
                        }"
                      >
                        <v-chip
                          small
                          class="white--text"
                          :color="
                            item.locationDocumentSubdivisions.length >=
                            subdivisions.length
                              ? 'success'
                              : item.locationDocumentSubdivisions.length == 0
                              ? 'danger'
                              : 'warning'
                          "
                          >{{
                            item.locationDocumentSubdivisions.length
                          }}</v-chip
                        >
                      </template>

                      <template
                        v-slot:[`item.locationSupportingDocuments.length`]="{
                          item,
                        }"
                      >
                        <v-chip
                          small
                          class="white--text"
                          color="primary"
                          v-if="item.locationSupportingDocuments.length > 0"
                          >{{ item.locationSupportingDocuments.length }}</v-chip
                        >
                      </template>
                      <template v-slot:[`item.productGroups`]="{ item }">
                        <v-chip
                          small
                          class="white--text"
                          v-if="
                            item.locationDocumentProducts.filter(
                              (x) => x.productGroupId
                            ).length > 0
                          "
                          >{{
                            item.locationDocumentProducts.filter(
                              (x) => x.productGroupId
                            ).length
                          }}</v-chip
                        >
                      </template>
                      <template v-slot:[`item.products`]="{ item }">
                        <v-chip
                          small
                          class="white--text"
                          v-if="
                            item.locationDocumentProducts.filter(
                              (x) => x.productId
                            ).length > 0
                          "
                          >{{
                            item.locationDocumentProducts.filter(
                              (x) => x.productId
                            ).length
                          }}</v-chip
                        >
                      </template>

                      <template v-slot:[`item.regions`]="{ item }">
                        <v-chip
                          small
                          class="white--text"
                          v-if="
                            item.locationDocumentAreas.filter((x) => x.regionId)
                              .length > 0
                          "
                          >{{
                            item.locationDocumentAreas.filter((x) => x.regionId)
                              .length
                          }}</v-chip
                        >
                      </template>
                      <template v-slot:[`item.countries`]="{ item }">
                        <v-chip
                          small
                          class="white--text"
                          v-if="
                            item.locationDocumentAreas.filter(
                              (x) => x.countryRegionId
                            ).length > 0
                          "
                          >{{
                            item.locationDocumentAreas.filter(
                              (x) => x.countryRegionId
                            ).length
                          }}</v-chip
                        >
                      </template>

                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-row class="mt-3 mb-2">
                            <v-col
                              cols="1"
                              v-if="$vuetify.breakpoint.mdAndUp"
                            ></v-col>
                            <v-col cols="12" class="my-0 py-0">
                              <v-card
                                outlined
                                v-for="variation in item.locationDocumentSubtypes"
                                :key="variation.id"
                                align="center"
                                class="pa-0 ma-0"
                              >
                                <v-card-text class="pa-0 ma-0">
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      md="2"
                                      class="mt-2 text-center"
                                    >
                                      <v-list-item style="height: 20px">
                                        <v-list-item-action>
                                          <v-chip>{{ variation.rank }}</v-chip>
                                        </v-list-item-action>
                                        <v-list-item-content class="text-left">
                                          <v-list-item-title>
                                            {{ variation.name }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-row>
                                            <v-btn
                                              class="mr-2"
                                              icon
                                              @click="
                                                editVariationDocument(variation)
                                              "
                                              ><v-icon small
                                                >edit</v-icon
                                              ></v-btn
                                            >
                                          </v-row>
                                        </v-list-item-action>
                                      </v-list-item>
                                      <v-list-item
                                        style="height: 20px"
                                        v-if="
                                          variation.file &&
                                          typeof variation.file == 'string'
                                        "
                                      >
                                        <v-list-item-action>
                                          <v-img
                                            style="width: 30px; height: 30px"
                                            :src="getIcon(variation.fileName)"
                                          >
                                          </v-img>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title class="text-left">
                                            {{ variation.fileName }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-row justify="center">
                                            <v-btn
                                              icon
                                              @click="
                                                downloadDocument(variation)
                                              "
                                              ><v-icon>download</v-icon>
                                            </v-btn>
                                          </v-row>
                                        </v-list-item-action>
                                      </v-list-item>
                                      <!-- <v-row align="center" justify="start">
                                      
                                      

                                        <h3>{{ variation.name }}</h3>
                                      </v-row> -->
                                      <v-col class="text-left mt-0 pt-0">
                                        <span
                                          style="
                                            font-weight: light;
                                            font-size: 12px;
                                          "
                                          >{{ variation.description }}</span
                                        >
                                      </v-col>
                                    </v-col>
                                    <v-col cols="12" sm="2" class="mr-0 pr-0">
                                      <v-list subheader dense>
                                        <v-subheader
                                        >
                                          Regions
                                          <v-spacer></v-spacer>
                                          <v-chip
                                            color="success"
                                            outlined
                                            small
                                            v-if="
                                              variation.regionMode == 'Include'
                                            "
                                            >Include</v-chip
                                          >
                                          <v-chip
                                            outlined
                                            color="danger"
                                            small
                                            v-else
                                            >Exclude</v-chip
                                          >
                                        </v-subheader>

                                        <v-row
                                          class="mb-1"
                                          v-if="!variation.allRegion"
                                        >
                                          <v-chip
                                            small
                                            class="mr-1 mt-1"
                                            outlined
                                            v-for="regionData in regionFilter(
                                              variation
                                            )"
                                            :key="regionData.id"
                                          >
                                            {{ regionData.name }}
                                          </v-chip>
                                        </v-row>

                                        <v-list-item v-if="variation.allRegion">
                                          <v-list-item-action>
                                            <v-icon
                                              v-if="
                                                variation.regionMode ==
                                                'Include'
                                              "
                                              color="success"
                                              >check_circle_outline</v-icon
                                            >
                                            <v-icon v-else color="danger"
                                              >cancel</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              class="text-left"
                                            >
                                              All Regions
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>

                                       
                                      </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="2" class="mr-0 pr-0">
                                      <v-list subheader dense>
                                        <v-subheader
                                        >
                                          Countries
                                          <v-spacer></v-spacer>
                                          <v-chip
                                            color="success"
                                            outlined
                                            small
                                            v-if="
                                              variation.countryMode == 'Include'
                                            "
                                            >Include</v-chip
                                          >
                                          <v-chip
                                            outlined
                                            color="danger"
                                            small
                                            v-else
                                            >Exclude</v-chip
                                          >
                                        </v-subheader>
                                        <v-list-item
                                          v-if="variation.allCountry"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              v-if="
                                                variation.countryMode ==
                                                'Include'
                                              "
                                              color="success"
                                              >check_circle_outline</v-icon
                                            >
                                            <v-icon v-else color="danger"
                                              >cancel</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              class="text-left"
                                            >
                                              All Countries
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <div v-else class="ml-2 my-4 pt-3">
                                          <v-row>
                                            <v-chip
                                              small
                                              class="mr-1 mt-1"
                                              outlined
                                              v-for="countryData in countryFilter(
                                                variation
                                              )"
                                              :key="countryData.id"
                                            >
                                              <v-avatar
                                                left
                                                size="24"
                                                class="mr-1"
                                                v-if="countryData.iso2"
                                              >
                                                <v-img
                                                  contain
                                                  :src="`https://cdn.loglive.io/flags/4x3/${countryData.iso2.toLowerCase()}.svg`"
                                                ></v-img>
                                              </v-avatar>
                                              {{ countryData.name }}
                                            </v-chip>
                                          </v-row>
                                        </div>
                                      </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="2" class="mr-0 pr-0">
                                      <v-list subheader dense>
                                        <v-subheader
                                        >
                                          Product Groups
                                          <v-spacer></v-spacer>
                                          <v-chip
                                            color="success"
                                            outlined
                                            small
                                            v-if="
                                              variation.productGroupMode ==
                                              'Include'
                                            "
                                            >Include</v-chip
                                          >
                                          <v-chip
                                            outlined
                                            color="danger"
                                            small
                                            v-else
                                            >Exclude</v-chip
                                          >
                                        </v-subheader>
                                        <v-list-item
                                          v-if="variation.allProductGroup"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              v-if="
                                                variation.productGroupMode ==
                                                'Include'
                                              "
                                              color="success"
                                              >check_circle_outline</v-icon
                                            >
                                            <v-icon v-else color="danger"
                                              >cancel</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              class="text-left"
                                            >
                                              All Product Groups
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <div
                                          v-else
                                          class="ml-2 my-4 pt-3"
                                        >
                                          <v-row>
                                            <v-chip
                                              small
                                              class="mr-1 mt-1"
                                              outlined
                                              v-for="productGroupData in productGroupFilter(
                                                variation
                                              )"
                                              :key="productGroupData.id"
                                            >
                                              {{ productGroupData.name }}
                                            </v-chip>
                                          </v-row>
                                        </div>
                                        </v-list>
                                        </v-col>

                                    <v-col cols="12" sm="2" class="mr-0 pr-0">
                                      <v-list subheader dense>
                                       

                                        <v-subheader
                                        >
                                          Products
                                          <v-spacer></v-spacer>
                                          <v-chip
                                            color="success"
                                            outlined
                                            small
                                            v-if="
                                              variation.productMode == 'Include'
                                            "
                                            >Include</v-chip
                                          >
                                          <v-chip
                                            outlined
                                            color="danger"
                                            small
                                            v-else
                                            >Exclude</v-chip
                                          >
                                        </v-subheader>
                                        <v-list-item
                                          v-if="variation.allProduct"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              v-if="
                                                variation.productMode ==
                                                'Include'
                                              "
                                              color="success"
                                              >check_circle_outline</v-icon
                                            >
                                            <v-icon v-else color="danger"
                                              >cancel</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              class="text-left"
                                            >
                                              All Products
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <div
                                          v-else
                                          class="ml-2 my-4 pt-3"
                                        >
                                          <v-row>
                                            <v-chip
                                              small
                                              class="mr-1 mt-1"
                                              outlined
                                              v-for="productData in productFilter(
                                                variation
                                              )"
                                              :key="productData.id"
                                            >
                                              {{ productData.name }}
                                            </v-chip>
                                          </v-row>
                                        </div>
                                      </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                      <v-list subheader dense>
                                       

                                        <v-subheader
                                        >
                                          Varieties
                                          <v-spacer></v-spacer>
                                          <v-chip
                                            color="success"
                                            outlined
                                            small
                                            v-if="
                                              variation.varietyMode == 'Include'
                                            "
                                            >Include</v-chip
                                          >
                                          <v-chip
                                            outlined
                                            color="danger"
                                            small
                                            v-else
                                            >Exclude</v-chip
                                          >
                                        </v-subheader>
                                        <v-list-item
                                          v-if="variation.allVariety"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              v-if="
                                                variation.varietyMode ==
                                                'Include'
                                              "
                                              color="success"
                                              >check_circle_outline</v-icon
                                            >
                                            <v-icon v-else color="danger"
                                              >cancel</v-icon
                                            >
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              class="text-left"
                                            >
                                              All Products
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <div
                                          v-else
                                          class="ml-2 my-4 pt-3"
                                        >
                                          <v-row>
                                            <v-chip
                                              small
                                              class="mr-1 mt-1"
                                              outlined
                                              v-for="productData in varietyFilter(
                                                variation
                                              )"
                                              :key="productData.id"
                                            >
                                              {{ productData.name }}
                                            </v-chip>
                                          </v-row>
                                        </div>
                                      </v-list>
                                    </v-col>
                                  </v-row>
                                </v-card-text></v-card
                              >
                            </v-col>
                           
                          </v-row>
                        </td>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>

          <div v-else-if="tabs[selectedTab] == 'Classification Rules'">
            <SupportingDocument
              :supportingDocuments="supportingDocuments"
              :country="country"
              @updated="supportingDocumentUpdate"
              @refresh="getSupportingDocuments"
            />
          </div>

         

          <div v-else-if="tabs[selectedTab] == 'Products'">
            <Product :country="country" />
          </div>

          <div v-else-if="tabs[selectedTab] == 'Varieties'">
            <Variety :countryCode="country.iso2" />
          </div>

          <div v-else-if="country.iso2=='ZA' && tabs[selectedTab] == 'FBO List'">
            <FBOList/>
          </div>

          <div v-else-if="tabs[selectedTab] == 'System Tools'">
            <Tools :country="country" :documents="filterGenerateDocuments" />
          </div>
        </v-card-text>
      </v-card>

      <!-- Document Modal -->
      <v-dialog
        v-model="documentModal"
        persistent
        width="1200px"
        :fullscreen="$vuetify.breakpoint.mobile"
        scrollable
      >
        <LocationDocumentModal
          :key="documentModalKey"
          :index="0"
          :item="item"
          :country="country"
          :products="products"
          :regions="regions"
          :subdivisions="subdivisions"
          :supportingDocuments="supportingDocuments"
          :regulatoryBodies="regulatoryBodies"
          :type="item.modalType"
          :integrationOptions="integrationOptions"
          @close="(documentModal = false), (item = {})"
          @update="updatedDocument"
          @updateFile="fileUpdated"
          @newVersion="versionUpdate"

        ></LocationDocumentModal>
      </v-dialog>

      <!-- Regulatory Body -->
      <v-dialog
        v-model="regulatoryBodyModal"
        persistent
        width="400px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <v-card>
          <RegulatoryBody
            :item="regulatoryBodyItem"
            @updated="
              getRegulatoryBodies(),
                (regulatoryBodyModal = false),
                (regulatoryBodyItem = {})
            "
            @close="(regulatoryBodyModal = false), (regulatoryBodyItem = {})"
          />
        </v-card>
      </v-dialog>

      <!-- Port Modal -->
      <v-dialog
        v-model="portModal"
        persistent
        width="400px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <v-card>
          <v-toolbar flat color="transparent">
            <v-toolbar-title> Manage Port </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text @click="(portItem = {}), (portModal = false)">X</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              label="Name*"
              v-model="portItem.name"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              label="Port Code*"
              v-model="portItem.code"
              outlined
              dense
            ></v-text-field>
            <v-select
              dense
              :items="portTypes"
              v-model="portItem.type"
              outlined
              label="Port Type"
            ></v-select>
            <v-text-field
              label="Country Name"
              disabled
              v-model="portItem.country"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              label="Country Code"
              disabled
              v-model="portItem.countryCode"
              outlined
              dense
            ></v-text-field>
            <v-row justify="center" class="mt-2">
              <v-btn
                text
                color="primary"
                @click="savePort"
                :disabled="!portItem.name || !portItem.code"
                :loading="submittingPort"
                >Submit</v-btn
              >
            </v-row>
          </v-card-text>
        </v-card></v-dialog
      >

      <!-- Port Modal -->
      <v-dialog
        v-model="subdivisionModal"
        persistent
        width="400px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <v-card>
          <v-toolbar flat color="transparent">
            <v-toolbar-title> Manage Subdivision </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="(subdivisionItem = {}), (subdivisionModal = false)"
              >X</v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-text-field
              label="Name*"
              v-model="subdivisionItem.name"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              label="Code*"
              v-model="subdivisionItem.code"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              label="Type*"
              placeholder="e.g. Province, District, State etc."
              v-model="subdivisionItem.type"
              outlined
              dense
            ></v-text-field>
            <v-row justify="center" class="mt-2">
              <v-btn
                text
                color="primary"
                @click="saveSubdivision"
                :disabled="
                  !subdivisionItem.name ||
                  !subdivisionItem.code ||
                  !subdivisionItem.type
                "
                :loading="submittingSubdivision"
                >Submit</v-btn
              >
            </v-row>
          </v-card-text>
        </v-card></v-dialog
      >
    </div>
    <div
      v-else
      style="height: 90vh; padding-top: 0; margin-top: 0"
      class="text-center"
    >
      <valhalla-loading />
    </div>
  </div>
</template>
<script>
import {
  gmapApi,
  // Map
} from "vue2-google-maps";
import CountryIntegration from "./CountryIntegration.vue";
import FBOList from "./FBOList.vue";
import RegulatoryBody from "./RegulatoryBodyModal.vue";
import LocationDocumentModal from "./LocationDocumentModal.vue";
import Product from "./Product.vue";
import SupportingDocument from "./SupportingDoc.vue";
import Tools from "./Tools.vue";

export default {
  components: {
    CountryIntegration,
    FBOList,
    LocationDocumentModal,
    Product,
    RegulatoryBody,
    SupportingDocument,
    Tools,
  },
  data: () => ({
    center: {
      lng: 18.427059,
      lat: -33.919473,
    },
    country: null,
    countryDocuments: [],
    allDocuments: [],
    documents: [],
    document: {},
    documentVersionModal: false,
    documentModal: false,
    documentModalKey: 0,
    documentHeaders: [
      {
        text: "Action",
        align: "center",
        sortable: false,
        value: "action",
        width: "10vw",
      },
      {
        text: "Rank",
        value: "rank",
        align: "center",
        width: "100px",
      },
      {
        text: "Regulatory Body",
        value: "regulatoryBody.name",
        align: "left",
        width: "10vw",
      },
      {
        text: "Name",
        value: "name",
        align: "left",
        width: "15vw",
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        align: "left",
        width: "30vw",
      },
      {
        text: "Responsible Party",
        value: "responsiblePartyType",
        sortable: false,
        align: "left",
      },
      {
        text: "Sharing Settings",
        value: "shared",
        sortable: false,
        align: "left",
      },
    ],
    countries: [],
    displayPorts: false,
    expanded: [],
    importExpanded: [],
    integrationOptions: [],
    fileList: [],
    item: {},
    map: null,
    marker: null,
    markerKey: 0,
    loadingPorts: false,
    ports: [],
    portItem: {},
    portModal: false,
    portMarkers: [],
    portTypes: ["Sea", "River", "Air", "Dry"],
    loading: false,
    loadingDocuments: false,
    productGroups: [],
    products: [],
    varieties: [],
    regulatoryBodies: [],
    regulatoryBodyModal: false,
    regulatoryBodyItem: {},
    regions: [],
    savingDocument: false,
    searchCountry: null,
    searchExportDocuments: null,
    searchImportDocuments: null,
    searchPorts: null,
    selectedTab: 0,
    subdivisionItem: {},
    subdivisionModal: false,
    submittingSubdivision: false,
    submittingPort: false,
    showByIndex: null,
    showMarker: false,
    subdivisions: [],
    supportingDocuments: [],
    tabs: [
      "Info",
      "Country Integrations",
      "Export Documents",
      "Import Documents",
      "Classification Rules",
      "Products",
      // "Varieties",
      "FBO List",
      "System Tools",
    ],
    zoom: 4,
    seearchSubDivisions: null,
  }),
  computed: {
    google: gmapApi,
    importDocuments() {
      let result =
        this.country && this.countryDocuments
          ? this.countryDocuments.filter((x) => x.type == "Import")
          : [];
      return result.sort((a, b) =>
        a.rank > b.rank ? 1 : b.rank > a.rank ? -1 : 0
      );
    },
    exportDocuments() {
      let result =
        this.country && this.countryDocuments
          ? this.countryDocuments.filter((x) => x.type == "Export")
          : [];
      return result.sort((a, b) =>
        a.rank > b.rank ? 1 : b.rank > a.rank ? -1 : 0
      );
    },
    filterGenerateDocuments() {
      let result = this.countryDocuments
      // console.log(result)
      //   (x) =>
      //     x.type == "Export" &&
      //     ((x.quantityType == "Shipment" && x.file) ||
      //       x.locationDocumentSubtypes.some(
      //         (y) => y.file && y.quantityType == "Shipment"
      //       ))
      // );
      // console.log(result)
      return result;
    },
    filterPorts() {
      let result = this.ports;
      if (this.searchPorts) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchPorts.toLowerCase())
        );
      }
      return result;
    },
    filterDivisions() {
      let result = this.subdivisions;
      if (this.seearchSubDivisions) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.seearchSubDivisions.toLowerCase())
        );
      }
      return result;
    },
  },
  watch: {
    "$route.params.code": {
      immediate: true,
      handler: function (val) {
        if (val) this.load(val);
      },
    },
    displayPorts() {
      if (this.zoom > 10) {
        this.zoom = 4;
      }
    },
    selectedTab(val) {
      if (val == 0) {
        if (this.country.geoJSON) {
          setTimeout(() => {
            this.$refs.mapRef.$mapPromise.then(() => {
              this.loadMap(this.country);
            });
          }, 1000);
        }
      }
    },
  },
  methods: {
    addDocument(type) {
      this.item = {
        type: type,
        modalType: "new",
        countryRegionId: this.country.id,
        integrationType: "manual",
        quantityType: "Shipment",
        submissionType: "Digital",
        version: 1,
        fileList: [],
        locationSupportingDocuments: [],
        locationDocumentSubdivisions: [],
        locationDocumentProducts: [],
        locationDocumentAreas: [],
        locationDocumentSubtypes: [],
        locationDocumentPoints: [],
        locationDocumentAttests: [],
        countryMode: "Include",
        regionMode: "Include",
        productGroupMode: "Include",
        productMode: "Include",
        varietyMode: "Include",

        pointOfEntryMode: "Include",
        allCountry: false,
        allRegion: false,
        allProductGroup: false,
        allProduct: false,
        allVariety: false,

        shipper: false,
        forwarder: false,
        consignee: false,
        buyer: false,
        notifyParty: false,
        responsiblePartyType: null,
        submittedRule: 1,
        submittedRuleType: 'Before',
        submittedRuleEvent: 'etd',
        completedRule: 1,
        completedRuleType: 'Before',
        completedRuleEvent: 'etd',
      };
      this.documentModal = true;
      this.documentModalKey++;
    },
    addDocumentVersion(item) {
      this.document = item;
      this.document.parentDocumentId = item.id;
      delete this.document.id;
      this.document.version++;
      this.documentVersionModal = true;
    },
    addFiles() {
      this.$refs.uploader.$el.children[0].click();
    },
    addPort() {
      this.portItem = {
        country: this.country.name,
        countryCode: this.country.iso3,
        type: "Sea",
      };
      this.portModal = true;
    },
    addRegulatoryBody() {
      this.regulatoryBodyItem = {
        countryRegionId: this.country.id,
      };
      this.regulatoryBodyModal = true;
    },
    addSubdivision() {
      this.subdivisionItem = {
        countryRegionId: this.country.id,
      };
      this.subdivisionModal = true;
    },
    changeRank(type, item) {
      switch (type) {
        case "increase":
          item.rank--;
          break;
        case "decrease":
          item.rank++;
          break;
      }
      this.$API.updateLocationDocument({
        id: item.id,
        rank: item.rank,
      });
    },
    countryFilter(variation) {
      let countries = variation.locationDocumentAreas
        .filter((x) => x.countryRegionId)
        .map((x) => x.countryRegionId);
      return this.countries.filter((x) => countries.includes(x.id));
    },
    productFilter(variation) {
      let products = variation.locationDocumentProducts
        .filter((x) => x.productId)
        .map((x) => x.productId);
      return this.products.data.filter((x) => products.includes(x.id));
    },
    productGroupFilter(variation) {
      let productGroups = variation.locationDocumentProducts
        .filter((x) => x.productGroupId)
        .map((x) => x.productGroupId);
      return this.products.data.filter((x) => productGroups.includes(x.id));
    },
    varietyFilter(variation) {
      let products = variation.locationDocumentProducts
        .filter((x) => x.varietyId)
        .map((x) => x.varietyId);
      return this.products.data.filter((x) => products.includes(x.id));
    },
    regionFilter(variation) {
      let regions = variation.locationDocumentAreas
        .filter((x) => x.regionId)
        .map((x) => x.regionId);
      return this.regions.filter((x) => regions.includes(x.id));
    },
    deleteDocument(item) {
      this.$confirm(
        "Are you sure you want to delete this document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateCountryDocument({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          let index = this.countryDocuments.indexOf(item);
          this.countryDocuments.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    deletePort(item) {
      this.$confirm("Are you sure you want to delete this port?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          await this.$API.updatePort({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          let index = this.ports.indexOf(item);
          this.ports.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    deleteSubdivision(item) {
      this.$confirm(
        "Are you sure you want to delete this subdivision?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateSubdivision({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          let index = this.subdivisions.indexOf(item);
          this.subdivisions.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    deleteRegulatoryBody(item) {
      this.$confirm(
        `Are you sure you want to remove ${item.name}?`,
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateRegulatoryBody({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.getRegulatoryBodies();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    async downloadDocument(doc) {
      let file = await this.$API.downloadFile({
        key: doc.file,
      });

      doc.file = file.file;
      doc.fileType = file.fileType;
      var hiddenElement = document.createElement("a");
      hiddenElement.href = file.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    async editDocument(item) {
      this.item = item;
      this.item.fileList = [];
      this.item.modalType = "modify";
      this.item.locationSupportingDocuments =
        await this.$API.getSupportingDocuments(this.item.id);
      this.documentModal = true;
      this.documentModalKey++;
    },
    async editVariationDocument(item) {
      this.item = item;
      this.item.fileList = [];
      this.item.modalType = "subtype";
      this.item.type = "subtype";

      this.item.locationSupportingDocuments =
        await this.$API.getSupportingDocuments(this.item.id);
      this.documentModal = true;
      this.documentModalKey++;
    },
    editPort(item) {
      this.portItem = item;
      this.portModal = true;
    },
    editRegulatoryBody(item) {
      this.regulatoryBodyItem = item;
      this.regulatoryBodyModal = true;
    },
    editSubdivision(item) {
      this.subdivisionItem = item;
      this.subdivisionModal = true;
    },
    expandRow(data) {
      let find = this.expanded.find((x) => x.id == data.id);
      if (!find) this.expanded = [data];
      else this.expanded = [];
    },
    formatNumber(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type && type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    async getRegulatoryBodies() {
      this.loadingRegulatoryBody = true;
      this.regulatoryBodies = await this.$API.getRegulatoryBodyByCountry(
        this.country.id
      );
      this.loadingRegulatoryBody = false;
    },
    async getSupportingDocuments() {
      this.supportingDocuments = await this.$API.getCountrySupportingDocuments(
        this.country.id
      );
    },
    // async getProducts() {
    //   if (this.searchTimer) {
    //     clearTimeout(this.searchTimer);
    //   }
    //   this.loading = true;
    //   this.searchTimer = setTimeout(async () => {
    //     this.products = await this.$API.listCountryProducts(this.countryCode, {
    //       params: this.params,
    //     });
    //     this.loading = false;
    //   }, 500);
    // },
    async load(code) {
      this.loading = true;
      this.country = await this.$API.getCountryByCode(code);
      this.integrationOptions = await this.$API.getIntegrationsByCountry(code)
      console.log(this.integrationOptions)
      this.countryDocuments = await this.$API.getCountryDocuments(
        this.country.id
      );
      this.subdivisions = await this.$API.getCountrySubdivisions(
        this.country.iso2
      );
      // this.productGroups = await this.$API.listCountryProductGroups(
      //   this.country.iso2
      // );
      this.products = await this.$API.getProducts({
        // params: {
        //   filter: {}
        // }
      });
      // this.products = products.data;
      this.regions = await this.$API.getRegions();
      this.countries = [].concat.apply(
        [],
        this.regions.map((x) => x.countryRegions)
      );

      this.getRegulatoryBodies();
      this.getSupportingDocuments();

      if (this.country.geoJSON) {
        setTimeout(() => {
          this.$refs.mapRef.$mapPromise.then(() => {
            this.loadMap(this.country);
          });
        }, 1000);
      }
      this.getPorts();

      this.loading = false;
    },
    async loadMap(obj) {
      this.$refs.mapRef.$mapPromise.then((map) => {
        this.map = map;
        this.map.data.addGeoJson(obj.geoJSON);
        this.center = {
          lng: obj.lng,
          lat: obj.lat,
        };
        this.zoom = 3;
        this.map.data.setStyle(() => {
          return {
            // fillColor: this.$vuetify.theme.currentTheme.primary,
            fillOpacity: 0.4,
            strokeWeight: 1,
            // strokeColor: this.$vuetify.theme.currentTheme.primary,
          };
        });
      });
    },
    async getPorts() {
      this.loadingPorts = true;
      this.ports = await this.$API.getPortsByCountry(this.country.iso2);
      this.portMarkers = this.ports.map((x) => ({
        id: x.id,
        label: x.name,
        name: x.name,
        code: x.code,
        marker: {
          lat: parseFloat(x.latitude),
          lng: parseFloat(x.longitude),
        },
      }));
      this.loadingPorts = false;
    },
    async getItem(id) {
      let result = await this.$API.getLocationDocument(id);
      return result;
    },

    async savePort() {
      this.submittingPort = true;
      if (this.portItem.id) {
        await this.$API.updatePort(this.portItem);
        let index = this.ports.indexOf(
          this.ports.find((x) => x.id == this.portItem.id)
        );
        this.ports[index] = this.portItem;
        this.$message.success("Successfully Updated!");
      } else {
        let result = await this.$API.createPort(this.portItem);
        this.ports.push(result);
        this.$message.success("Successfully created!");
      }
      this.portModal = false;
      this.submittingPort = false;
      this.portItem = {};
    },
    async saveSubdivision() {
      this.submittingSubdivision = true;
      if (this.subdivisionItem.id) {
        await this.$API.updateSubdivision(this.portItem);
        let index = this.subdivisions.indexOf(
          this.subdivisions.find((x) => x.id == this.subdivisionItem.id)
        );
        this.subdivisions[index] = this.subdivisionItem;
        this.$message.success("Successfully Updated!");
      } else {
        let result = await this.$API.createSubdivision(this.subdivisionItem);
        this.subdivisions.push(result);
        this.$message.success("Successfully created!");
      }
      this.subdivisionModal = false;
      this.submittingSubdivision = false;
      this.subdivisionItem = {};
    },
    async updateCountry(){
      await this.$API.updateCountry(this.country);
    },
    async updatedDocument(item) {
      if (this.item.id && !this.item.parentTypeDocumentId) {
        let result = await this.getItem(item.id);
        let find = this.countryDocuments.find((x) => x.id == item.id);
        if (find) {
          let index = this.countryDocuments.indexOf(find);
          this.countryDocuments[index] = result;
        } else {
          this.countryDocuments.push(result);
        }
      } else if (item.parentTypeDocumentId) {
        let findSupporting = [].concat.apply(
          [],
          this.countryDocuments.map((x) => x.locationDocumentSubtypes)
        );
        let find = findSupporting.find((x) => x.id == item.id);
        if (find) {
          let index = findSupporting.indexOf(find);
          findSupporting[index] = item;
        }
      }

      this.documentModal = false;
      this.documentVersionModal = false;
      this.document = {};
      this.item = {};
    },
    async supportingDocumentUpdate(item) {
      let find = this.supportingDocuments.find((x) => x.id == item.id);
      if (find) {
        let index = this.supportingDocuments.indexOf(find);
        this.supportingDocuments[index] = item;
      } else {
        this.supportingDocuments.push(item);
      }
    },
    fileUpdated(item) {
      let find = this.countryDocuments.find((x) => x.id == item.id);
      let index = this.countryDocuments.indexOf(find);
      this.countryDocuments[index].file = item.file;
      this.countryDocuments[index].fileName = item.fileName;
      this.documentVersionModal = false;
      this.document = {};
    },
    versionUpdate(item) {
      let find = this.countryDocuments.find((x) => x.id == item.replacementId);
      let index = this.countryDocuments.indexOf(find);
      this.countryDocuments[index] = item;
      this.documentVersionModal = false;
      this.document = {};
    },
    async viewPort(port) {
      this.displayPorts = false;
      this.center = {
        lat: parseFloat(port.latitude),
        lng: parseFloat(port.longitude),
      };
      this.marker = port;
      this.marker.marker = {
        lat: parseFloat(port.latitude),
        lng: parseFloat(port.longitude),
      };
      this.showMarker = true;
      this.zoom = 10;
      this.markerKey++;
    },
  },
};
</script>
<style scoped>
.v-subheader {
  background: var(--v-component-base);
}
</style>
